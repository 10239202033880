<template>
  <div>
    <div id="order-label-print" class="mt-10">
      <div>
        <qrcode-vue level="L" class="w-full h-full" size="260" :value="storageUnit ? storageUnit.ops_id : ''"/>
      </div>
      <p class="font-medium text-center text-4xl">{{storageUnit ? storageUnit.ops_id : ''}}</p>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  props: ['storageUnit'],
  components: {
    QrcodeVue
  }
}
</script>

<style lang="scss">
@media print{
  @page {
    size: letter;
  }
  #fc_frame {
    display: none;
  }
  .shipment-page,
  .dropdown-menu{
    *{
      visibility: hidden;
      color: black;
    }
    #order-label-print,
    #order-label-print *{
      visibility: visible;
    }
    #order-label-print{
      display: block;
    }
    #content-area {
      margin: 0 !important;
    }
    #order-label-print {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}
</style>